import { Options, Vue } from "vue-class-component";
import API from "@/api/auth";
import { useToast } from "vue-toastification";
import BackgroundImages from "@/components/background-images/background-images.vue";
import router from "@/router";
import LoginInfo from "@/entity/LoginInfo";

@Options({
    name: "app-login",
    components: {
        "app-background-images": BackgroundImages,
    },
    watch: {
        "loginInfo.rememberMe": function () {
            this.rememberMeChange();
        },
        "loginInfo.autoLogin": function () {
            this.autoLoginChange();
        },
    },
})
export default class Login extends Vue {
    public isAuthLoading = false;
    private toast = useToast();
    private loginInfo: LoginInfo = new LoginInfo();

    public mounted() {
        this.$store.dispatch("auth/setUserInfo", null);
        this.loginInfo = this.$store.getters["ui/loginInfo"] || new LoginInfo();
        if (this.loginInfo.autoLogin) this.loginByAuth();
    }

    private async loginByAuth(): Promise<void> {
        if (this.$tools.isEmpty(this.loginInfo.usercode)) {
            this.toast.error("工号不能为空！");
            return Promise.reject(false);
        }
        if (this.$tools.isEmpty(this.loginInfo.password)) {
            this.toast.error("密码不能为空！");
            return Promise.reject(false);
        }
        this.isAuthLoading = true;
        await API.login({
            usercode: this.loginInfo.usercode,
            password: this.loginInfo.password,
        })
            .then((res: any) => {
                this.toast.success(res.msg);
                this.$store.dispatch("auth/setUserInfo", res.data);
                if (this.loginInfo.rememberMe) {
                    this.$store.dispatch("ui/setLoginInfo", this.loginInfo);
                } else {
                    this.$store.dispatch("ui/setLoginInfo", null);
                }
                const userInfo = this.$store.getters["auth/userInfo"];
                if (userInfo && userInfo.userIsOne && userInfo.userIsOne === 1) {
                    router.replace("/loginModPass");
                } else {
                    router.replace("/");
                }
            })
            .finally(() => {
                this.isAuthLoading = false;
            });
    }

    public rememberMeChange() {
        if (!this.loginInfo.rememberMe) this.loginInfo.autoLogin = false;
    }

    public autoLoginChange() {
        if (this.loginInfo.autoLogin) this.loginInfo.rememberMe = true;
    }

    get siteTitle() {
        return this.$store.getters["ui/siteTitle"];
    }
}
